import React from "react";
import "./Watch.css";
import video from "../../assets/video.png";
import frame1 from "../../assets/Frame1.png";
import frame2 from "../../assets/Frame2.png";

const Watch = () => {
  return (
    <div className="watch">
      <div className="w-header">Click to watch video about us</div>
      <div className="w-video">
        <img src={video} alt="" />
      </div>

      <div className="partner">
        <div className="p-header">Our Partner</div>
        <div className="p-frame1">
          <img src={frame1} alt="" />
        </div>
        <div className="p-frame2">
          <img src={frame2} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Watch;
