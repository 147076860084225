import React from "react";
import "./Products.css";
import image1 from "../../assets/SIMSAVERS LOGO2-03 copy.png";
import image2 from "../../assets/topitup-logo-nav-bar.png";
import image3 from "../../assets/pmc logo white-01.png";
import image4 from "../../assets/smecloud logo -01 1.png";
const Products = () => {
  return (
    <div className="products">
      <p className="p-heading">Our Products</p>
      <div className="p-major">
        <div className="p-tab">
          <img src={image1} alt="" />
          <p>Automate your recharge platform with your own SIM</p>
          <span></span>
          <div className="p-text">Get Started</div>
        </div>
        <div className="p-tab">
          <img src={image2} alt="" />
          <p>
            Platform to buy airtime&Data, Pay TV, subscription, Pay electric
            bills
          </p>
          <span></span>
          <button>GET STARTED</button>
        </div>
        <div className="p-tab">
          <img src={image3} alt="" />
          <p>Our fast and reliable way to send bulk sms</p>
          <span></span>
          <button>GET STARTED</button>
        </div>
        <div className="p-tab">
          <img src={image4} alt="" />
          <p>
            we render help to people as subscribers to all networks @ the
            cheapest internet price rate
          </p>
          <span></span>
          <button>GET STARTED</button>
        </div>
      </div>
    </div>
  );
};

export default Products;
