// import logo from './logo.svg';
import "./App.css";
import NavBar from "./components/Navbar/NavBar";
import Hero from "./components/Hero/Hero";
import Body from "./components/Body/Body";
import Services from "./components/Services/Services";
import Products from "./components/Products/Products";
import Watch from "./components/Watch/Watch";

function App() {
  return (
    <div className="App">
      <Body />
      <NavBar />
      <Hero />
      <Services />
      <Products />
      <Watch />
    </div>
  );
}

export default App;
