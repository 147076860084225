import React from "react";
import "./Services.css";
const Services = () => {
  return (
    <div className="services">
      <div className="s-heading">
        <p>What We Do</p>
      </div>
      <div className="s-links">
        <ul style={{ listStyleType: "none" }}>
          <li>AirtimeData</li>
          <li>Bills payment</li>
          <li>Bulk sms</li>
          <li>Tracker subscription</li>
          <li>Automation</li>
        </ul>
      </div>
    </div>
  );
};

export default Services;
