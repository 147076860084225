import React from "react";
import backg from "../../assets/web concept.png";
import iconPlay from "../../assets/Icon_play.png";
import "./Hero.css";
const Hero = () => {
  return (
    <div className="hero">
      <div className="h-background">
        <img src={backg} alt="" />
        <p>We see the future from now</p>
        <div className="h-video">
          <div className="h-icon">
            <img src={iconPlay} alt="" />
          </div>
          <span>Watch video about us</span>
        </div>
      </div>
    </div>
  );
};

export default Hero;
