import React from "react";
import Logo from "../../assets/CONCEPTDELAPAIX LOGO 1.png";
import "./NavBar.css";
const NavBar = () => {
  return (
    <div className="navigation">
      <div className="nav-left">
        <img src={Logo} alt="" className="logo" />
      </div>
      <div className="nav-right">
        <div className="nav-menu">
          <ul style={{ listStyleType: "none" }}>
            <li>Home</li>
            <li>About Us</li>
            <li>Our Brands</li>
            <li>Contact Us</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
